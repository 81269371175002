import BaseMethods from '../BaseMethods';
import {getData} from "@/api/v2/utils/DataHandler";

export default new (class extends BaseMethods {
    async store(taskId) {
        const route = `/api/v2/tasks/${taskId}/identity-confirmation-codes`;

        return this.asJson().post(route).then(getData);
    }
})();
