import BaseMethods from '../BaseMethods';
import {getData} from "@/api/v2/utils/DataHandler";

export default new (class extends BaseMethods {
    endpointRoute = '/api/v2/config';

    async eSignatureTermsOfService() {
        return this.asJson().get(`${this.endpointRoute}/e-signature/terms-of-service`).then(getData)
    }
})();
