<template>
    <CModal size="lg" class="py-5 h-auto" :fullscreen="false" :keyboard="false" :visible="visible"
            @close="$emit('close')">
        <CModalHeader>
            <CModalTitle>Almost done!</CModalTitle>
        </CModalHeader>
        <CModalBody class="content-body py-3">
            <div class="code-container">
                <h6>1. Identity confirmation</h6>
                <p class="text-information small mt-4">
                    We’ve sent a confirmation code to your email at {{ maskEmail(user.email) }}. <br>
                    Please provide the code below to proceed.
                </p>
                <CNInput v-for="(code, index) in form.identityCode" :key="index" type="tel" class="input"
                         v-mask="'#'" :ref="`input-${index}`" v-model="form.identityCode[index]"
                         @input="nextCodeField(index)" @keydown.backspace="previousCodeField(index, $event)"
                         @paste="pasteIdentityCode($event)"/>
                <div class="text-error small mt-1">{{ codeError }}</div>
                <div v-if="codeTimerSeconds === 0" class="small mt-1">
                    <a href="#" @click.prevent="requestIdentityCode">Request a new code</a>
                </div>
                <div v-else-if="codeTimerSeconds" class="text-information small mt-1">
                    You can request a new code in {{ formattedTimer }}.
                </div>
                <h6 class="mt-4">2. Review and Accept Terms</h6>
                <p>
                    <label for="terms-of-service" class="text-information small">
                        <input type="checkbox" id="terms-of-service" v-model="form.termsAccepted">
                        I agree I am legally signing this document and agreeing to the eSignature
                        <a :href="termsOfService" target="_blank">Terms of Service</a>.
                    </label>
                </p>
            </div>
        </CModalBody>
        <CModalFooter class="justify-content-center position-relative">
            <LoadingButton color="primary" :loading="loading" :disabled="!isFormValid || loading" class="mb-3"
                           @click="$emit('submit', fullIdentityCode, form.termsAccepted)">
                Sign and Submit
            </LoadingButton>
        </CModalFooter>
    </CModal>
</template>

<script>
import {mapGetters} from "vuex";
import LoadingButton from "@/components/LoadingButton.vue";
import {mask} from "vue-the-mask";
import emailFormatter from "@/mixin/emailFormatter";
import Config from "@/api/v2/endpoints/Config";
import TaskIdentityConfirmationCodes from "@/api/v2/endpoints/TaskIdentityConfirmationCodes";
import apiErrorHandler from "@/mixin/apiErrorHandler";

const formData = () => ({
    identityCode: ['', '', '', ''],
    termsAccepted: false,
});

const SECONDS_TO_A_NEW_CODE = 5 * 60;

export default {
    name: 'TaskESignatureIdentityConfirmationModal',
    components: {LoadingButton},
    emits: ['close', 'submit'],
    mixins: [emailFormatter, apiErrorHandler],
    directives: {mask},
    props: {
        visible: Boolean,
        loading: Boolean,
        task: Object,
        codeError: String,
    },
    data() {
        return {
            form: formData(),
            termsOfService: null,
            codeTimerSeconds: null,
            codeTimerInterval: null,
        };
    },
    computed: {
        ...mapGetters(['user']),
        fullIdentityCode() {
            return this.form.identityCode.join("");
        },
        isFullIdentityCodeValid() {
            return this.validateIdentityCode(this.fullIdentityCode);
        },
        isFormValid() {
            return this.isFullIdentityCodeValid && this.form.termsAccepted;
        },
        formattedTimer() {
            const minutes = Math.floor(this.codeTimerSeconds / 60);
            const seconds = this.codeTimerSeconds % 60;
            return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        },
    },
    watch: {
        visible(val) {
            if (val) {
                this.clearForm();
                this.requestIdentityCode();
            }
        }
    },
    created() {
        this.loadTermsOfService();
        this.resetCodeTimerSeconds();
    },
    methods: {
        loadTermsOfService() {
            this.resetCodeTimerSeconds();

            Config
                .eSignatureTermsOfService()
                .then(response => this.termsOfService = response.data.link);
        },
        requestIdentityCode() {
            TaskIdentityConfirmationCodes
                .store(this.task.id)
                .finally(() => this.startCodeTimer());
        },
        nextCodeField(index) {
            if (this.form.identityCode[index] && ++index < this.form.identityCode.length) {
                this.$refs[`input-${index}`][0].handleFocus();
            }
        },
        previousCodeField(index, event) {
            if (!this.form.identityCode[index] && index > 0) {
                this.$refs[`input-${--index}`][0].handleFocus();
                event.preventDefault();
            }
        },
        validateIdentityCode(code) {
            return code.length === this.form.identityCode.length && /^\d+$/.test(code);
        },
        pasteIdentityCode(event) {
            const code = event.clipboardData.getData("text").trim();

            if (this.validateIdentityCode(code)) {
                this.form.identityCode = code.split("");
                this.$nextTick(() => this.$refs[`input-${this.form.identityCode.length - 1}`][0].handleFocus());
            }

            event.preventDefault();
        },
        startCodeTimer() {
            this.resetCodeTimerSeconds();
            this.clearCodeTimer();

            this.codeTimerInterval = setInterval(() => {
                if (this.codeTimerSeconds > 0) {
                    return this.codeTimerSeconds--;
                }

                this.clearCodeTimer();
            }, 1000);
        },
        clearCodeTimer() {
            if (this.codeTimerInterval) {
                clearInterval(this.codeTimerInterval);
                this.codeTimerInterval = null;
            }
        },
        resetCodeTimerSeconds() {
            this.codeTimerSeconds = SECONDS_TO_A_NEW_CODE;
        },
        clearForm() {
            this.form = formData();
        },
    }
};
</script>

<style lang="scss" scoped>
.code-container {
    text-align: center;

    .input {
        width: 40px !important;
        display: inline-block;
        margin: 0 5px;
    }
}
</style>
