<template>
    <CModal scrollable fullscreen :keyboard="false" :visible="visible" @show="setUpDocumentPreview" @close="closeEvent">
        <CModalHeader>
            <CModalTitle>Sign document: {{ task.file_name }}</CModalTitle>
        </CModalHeader>
        <CModalBody class="content-body">
            <div class="webviewer-container">
                <div class="webviewer" ref="viewer"></div>
            </div>
            <TaskESignatureIdentityConfirmationModal :visible="confirmationModal" :loading="submitting" :task="task"
                                                     :code-error="validationErrors.identity_code" @submit="sign"
                                                     @close="toggleConfirmationModal"/>
            <ConfirmDialog ref="confirmDialog" class="py-5 h-auto text-center"/>
        </CModalBody>
        <CModalFooter v-if="readyToSign" class="justify-content-center p-0 pb-2 position-relative">
            <LoadingButton :loading="submitting" :aria-disabled="disableSubmitButton" color="primary"
                           @click="completeAgreement">
                Complete Agreement
            </LoadingButton>
        </CModalFooter>
    </CModal>
</template>

<script>
import Tasks from "@/api/v2/endpoints/Tasks";
import {
    BUYER_INITIALS,
    BUYER_SIGNATURE,
    BUYER_SIGNATURE_DATE,
    FILL_AND_SIGN_FIELDS,
    SELLER_INITIALS,
    SELLER_SIGNATURE,
    SELLER_SIGNATURE_DATE
} from "@/domain/Entities/Shortcode/shortcodes";
import {mapGetters} from "vuex";
import LoadingButton from "@/components/LoadingButton.vue";
import apiErrorHandler from "@/mixin/apiErrorHandler";
import fileExtension from "@/mixin/fileExtension";
import TaskESignatureIdentityConfirmationModal
    from "@/components/TaskPages/TaskESignatureIdentityConfirmationModal.vue";
import WebViewer from "@pdftron/webviewer";
import config from "@/domain/config";
import taskHelper from "@/mixin/taskHelper";
import {PDF} from "@/domain/Entities/Upload/extensions";
import ConfirmDialog from "@/components/Modals/ConfirmDialog.vue";
import {delay} from "lodash";
import dateFormater from "@/mixin/dateFormater";
import webviewerHelper from "@/mixin/webviewerHelper";

export default {
    name: 'TaskESignatureModal',
    components: {ConfirmDialog, TaskESignatureIdentityConfirmationModal, LoadingButton},
    mixins: [apiErrorHandler, fileExtension, taskHelper, dateFormater, webviewerHelper],
    emits: ['close', 'sign', 'upload', 'cancelUpload'],
    inject: ['toast'],
    props: {
        visible: Boolean,
        task: Object,
        file: File
    },
    data() {
        return {
            confirmationModal: false,
            readyToSign: false,
            submitting: false,
            webviewer: null,
            documentFields: [],
        };
    },

    computed: {
        ...mapGetters(['user']),
        signerSignatureFields() {
            return this.documentFields.filter(annotation => this.isSignatureField(annotation));
        },
        emptyDocumentFields() {
            return this.documentFields
                .filter(annotation => annotation.fieldFlags.get(this.webviewer.Core.Annotations.WidgetFlags.REQUIRED))
                .filter(annotation => this.isAnnotationFieldValueEmpty(annotation));
        },
        disableSubmitButton() {
            return (this.signerSignatureFields.length === 0 && !this.file) || this.emptyDocumentFields.length > 0;
        },
    },
    methods: {
        setUpDocumentPreview() {
            this.file
                ? this.instantiateWebviewer(this.file)
                : this.loadSignableDocument();
        },
        loadSignableDocument() {
            const promise = this.isPendingInternalSignature
                ? Tasks.downloadUploadedFile(this.task.id)
                : Tasks.downloadFile(this.task.id);

            promise.then(response => this.instantiateWebviewer(URL.createObjectURL(response.data)));
        },
        instantiateWebviewer(document = null) {
            WebViewer
                .Iframe({
                    fullAPI: true,
                    path: `${config.app.baseUrl}/webviewer`,
                    licenseKey: config.services.apryse.licenseKey,
                }, this.$refs.viewer)
                .then(instance => {
                    this.webviewer = instance;
                    this.setUpWebviewer();
                    this.webviewer.UI.loadDocument(document, {filename: this.task.file_name, extension: PDF});
                })
                .catch(error => {
                    console.error(error);
                    this.closeEvent();
                    this.toast('warning', 'The system was unable to load the document! Please contact support.');
                });
        },
        setUpWebviewer() {
            this.configWebviewerFeatures();

            this.webviewer.Core.annotationManager.setCurrentUser(this.user.full_name);

            this.webviewer.Core.documentViewer
                .addEventListener('annotationsLoaded', () => this.defineWebviewerAnnotationFields());

            this.webviewer.Core.documentViewer
                .getTool(this.webviewer.Core.Tools.ToolNames.SIGNATURE)
                .addEventListener('annotationAdded', annotation => this.setAnnotationFieldSigned(annotation));
        },
        configWebviewerFeatures() {
            this.webviewer.UI.setZoomLevel('100%');
            this.webviewer.UI.enableFeatures([this.webviewer.UI.Feature.Initials]);
            this.webviewer.UI.setToolMode(this.webviewer.Core.Tools.ToolNames.EDIT);
            this.webviewer.UI.disableElements([
                'annotationCommentButton',
                'default-ribbon-group',
                'default-top-header',
                'contextMenuPopup',
                'notesPanelToggle',
                'annotationPopup',
                'tools-header',
                'linkButton',
                'indexPanel',
                'textPopup',
            ]);
        },
        defineWebviewerAnnotationFields() {
            const {Annotations, annotationManager} = this.webviewer.Core;

            this.addSignerSignatureDate();

            this.documentFields = this
                .webviewerWidgetAnnotations()
                .map(widgetAnnotation => this.createInitialsFields(widgetAnnotation))
                .map(widgetAnnotation => this.disableWidgetAnnotation(widgetAnnotation))
                .filter(widgetAnnotation => !widgetAnnotation.fieldFlags.get(Annotations.WidgetFlags.READ_ONLY))
                .map(widgetAnnotation => this.setWidgetAnnotationIndicator(widgetAnnotation, true))
                .map(widgetAnnotation => this.makeSignatureFieldRequired(widgetAnnotation))
                .map(widgetAnnotation => this.setWidgetAnnotationEvents(widgetAnnotation));

            this.reviewFileSignatureFields();

            this.readyToSign = true;
        },
        addSignerSignatureDate() {
            const signatureDate = this.formatDate(new Date());

            if (this.isTaskGeneralContractor)
                return this.replaceContent(this.webviewer, 'MM/DD/YYYY', signatureDate);

            this.replaceContent(this.webviewer, SELLER_SIGNATURE_DATE.key, signatureDate);
            this.replaceContent(this.webviewer, BUYER_SIGNATURE_DATE.key, '[MM/DD/YYYY]');
        },
        webviewerWidgetAnnotations() {
            const {annotationManager, Annotations} = this.webviewer.Core;

            return annotationManager
                .getAnnotationsList()
                .filter(annotation => annotation instanceof Annotations.WidgetAnnotation)
        },
        createInitialsFields(widgetAnnotation) {
            if (this.isInitialsField(widgetAnnotation)) {
                this.webviewer.Core.annotationManager
                    .getFormFieldCreationManager()
                    .setSignatureOption(widgetAnnotation, 'initialsSignature');
            }

            return widgetAnnotation;
        },
        disableWidgetAnnotation(widgetAnnotation) {
            if (this.shouldDisableWidgetAnnotation(widgetAnnotation)) {
                widgetAnnotation.fieldFlags.set(this.webviewer.Core.Annotations.WidgetFlags.READ_ONLY)
            }

            return widgetAnnotation;
        },
        shouldDisableWidgetAnnotation(widgetAnnotation) {
            const isGeneralSignableField = widgetAnnotation.fieldName.includes(BUYER_INITIALS.key) || widgetAnnotation.fieldName.includes(BUYER_SIGNATURE.key);

            return (this.isTaskSubContractor && isGeneralSignableField) || (this.isTaskGeneralContractor && !isGeneralSignableField);
        },
        setWidgetAnnotationEvents(widgetAnnotation) {
            widgetAnnotation.addEventListener('commit', () => this.setAnnotationFieldValue(widgetAnnotation));
            widgetAnnotation.addEventListener('change', () => this.setAnnotationFieldValue(widgetAnnotation));

            return widgetAnnotation;
        },
        makeSignatureFieldRequired(widgetAnnotation) {
            if (this.isSignatureAnnotation(widgetAnnotation)) {
                widgetAnnotation.fieldFlags.set(this.webviewer.Core.Annotations.WidgetFlags.REQUIRED);
            }

            return widgetAnnotation;
        },
        setWidgetAnnotationIndicator(widgetAnnotation, active = false, label = null) {
            widgetAnnotation.setFieldIndicator(active, this.defineWidgetAnnotationIndicatorLabel(widgetAnnotation, label));
            this.webviewer.Core.annotationManager.trigger('annotationChanged', [[widgetAnnotation], 'modify', {}]);

            return widgetAnnotation;
        },
        defineWidgetAnnotationIndicatorLabel(annotation, label = null) {
            const dictionary = {
                TextFormField: 'Fill out',
                SignatureFormField: 'Sign',
                CheckBoxFormField: 'Check'
            };

            return `${label || dictionary[annotation.getField().getFieldType()] || 'Select'}: ${this.defineWidgetAnnotationName(annotation.fieldName)}`;
        },
        defineWidgetAnnotationName(currentFieldName) {
            const shortcode = FILL_AND_SIGN_FIELDS.find(item => currentFieldName.includes(item.key));

            return shortcode ? shortcode.label : currentFieldName;
        },
        setAnnotationFieldValue(changedWidgetAnnotation) {
            this.documentFields.map(annotationField => {
                if (this.areTheSameAnnotationFields(annotationField, changedWidgetAnnotation)) {
                    annotationField.value = changedWidgetAnnotation.value;

                    this.webviewerWidgetAnnotations()
                        .filter(widgetAnnotation => widgetAnnotation.fieldName === annotationField.fieldName)
                        .forEach(widgetAnnotation => this.setWidgetAnnotationIndicator(widgetAnnotation, true, 'Done'));
                }

                return annotationField;
            });
        },
        setAnnotationFieldSigned(changedWidgetAnnotation) {
            this.webviewerWidgetAnnotations().forEach(widgetAnnotation => {
                if (widgetAnnotation.isSignedByAppearance && widgetAnnotation.isSignedByAppearance()) {
                    this.documentFields.map(annotationField => {
                        if (widgetAnnotation === annotationField) {
                            annotationField.value = 'Signed';
                            this.setWidgetAnnotationIndicator(annotationField, true, 'Done');
                        }

                        return annotationField;
                    });
                }
            });
        },
        areTheSameAnnotationFields(annotationA, annotationB) {
            return annotationA.PageNumber === annotationB.PageNumber && annotationA.X === annotationB.X && annotationA.Y === annotationB.Y;
        },
        isAnnotationFieldValueEmpty(annotationField) {
            if (annotationField.getField().getFieldType() === 'RadioButtonFormField') {
                return this.documentFields.filter(annotation => {
                    return annotation.fieldName === annotationField.fieldName && annotation.value !== 'Off'
                }).length === 0;
            }

            return annotationField.value === '' || annotationField.value === 'Off';
        },
        revertDisabledWidgetAnnotations() {
            this.webviewerWidgetAnnotations()
                .map(annotation => {
                    annotation.fieldFlags.set(
                        this.webviewer.Core.Annotations.WidgetFlags.READ_ONLY,
                        !this.shouldDisableWidgetAnnotation(annotation)
                    );

                    return annotation;
                });
        },
        removeWidgetAnnotationRequiredFlag() {
            this.webviewerWidgetAnnotations()
                .forEach(widgetAnnotation => widgetAnnotation.fieldFlags
                    .set(this.webviewer.Core.Annotations.WidgetFlags.REQUIRED, false)
                );
        },
        removeWidgetAnnotationIndicators() {
            this.webviewerWidgetAnnotations()
                .forEach(widgetAnnotation => this.setWidgetAnnotationIndicator(widgetAnnotation));
        },
        prepareToSign() {
            if (this.isTaskSubContractor) {
                this.revertDisabledWidgetAnnotations();
            }

            this.removeWidgetAnnotationRequiredFlag();
            this.removeWidgetAnnotationIndicators();
        },
        async sign(identityCode, termsAccepted) {
            this.toggleSubmitting();
            this.clearValidationErrors();
            this.prepareToSign();

            Tasks
                .sign(this.task.id, await this.defineSignData(identityCode, termsAccepted))
                .then(() => {
                    this.toast('info', 'Document has been successfully signed!')
                    this.signEvent()
                })
                .catch(response => this.handleApiError(response))
                .finally(() => this.toggleSubmitting());
        },
        async defineSignData(identityCode, termsAccepted) {
            const data = await this.fileFormData();

            data.append('terms_accepted', termsAccepted);
            data.append('identity_code', identityCode);

            return data;
        },
        async fileFormData() {
            const editorDocument = this.webviewer.Core.documentViewer.getDocument();
            const xfdfString = await this.webviewer.Core.annotationManager.exportAnnotations();
            const file = this.convertArrayBufferToPdfBlob(await editorDocument.getFileData({xfdfString}));

            const data = new FormData();

            data.append('file', file, editorDocument.getFilename());

            return data;
        },
        async upload() {
            this.toggleSubmitting();
            this.clearValidationErrors();

            Tasks
                .signedContract(this.task.id, await this.fileFormData())
                .then(() => {
                    this.toast('info', 'Signed contract has been successfully upload!')
                    this.uploadEvent()
                })
                .catch(response => this.handleApiError(response))
                .finally(() => this.toggleSubmitting());
        },
        isSignatureAnnotation(annotation) {
            return annotation.isSignedByAppearance !== undefined;
        },
        isBuyerSignatureField(annotation) {
            return annotation.fieldName.includes(BUYER_SIGNATURE.key);
        },
        isBuyerInitialsField(annotation) {
            return annotation.fieldName.includes(BUYER_INITIALS.key);
        },
        isSellerSignatureField(annotation) {
            return annotation.fieldName.includes(SELLER_SIGNATURE.key);
        },
        isSellerInitialsField(annotation) {
            return annotation.fieldName.includes(SELLER_INITIALS.key);
        },
        isSignatureField(annotation) {
            return this.isSignatureAnnotation(annotation) && (
                this.isBuyerSignatureField(annotation) || this.isSellerSignatureField(annotation)
            );
        },
        isInitialsField(annotation) {
            return this.isSignatureAnnotation(annotation) && (
                this.isBuyerInitialsField(annotation) || this.isSellerInitialsField(annotation)
            );
        },
        async reviewFileSignatureFields() {
            if (!this.file) return;

            if (this.signerSignatureFields.length === 0) {
                await this.noSignatureFieldsAlert();
            }

            delay(() => {
                const pendingVendorSignatureFields = this.pendingVendorSignatureFields();

                if (pendingVendorSignatureFields.length > 0) {
                    this.pendingVendorSignatureFieldsAlert(pendingVendorSignatureFields);
                }
            }, 500);
        },
        async noSignatureFieldsAlert() {
            await this.$refs.confirmDialog.confirm({
                text: 'No signatures needed from you!',
                info: 'The document appears to have no signature fields that are designated for you. <br> Click Complete Agreement to continue uploading.',
                onlyConfirmButton: true,
                confirmText: 'Got it!',
            });
        },
        pendingVendorSignatureFieldsAlert(signatureFields) {
            let pages = signatureFields.map(annotation => annotation.PageNumber);
            pages = Array.from(new Set(pages)).toString();

            this.$refs.confirmDialog
                .confirm({
                    text: 'Pending vendor signatures detected!',
                    info: `The system detected signature field(s) assigned to your vendor that remain unsigned on page(s) ${pages}. <br> You can choose to move forward with the contract by clicking Complete Agreement.`,
                    confirmText: 'Continue',
                    cancelText: 'Cancel',
                })
                .then(response => {
                    if (!response) this.cancelUploadEvent();
                });
        },
        pendingVendorSignatureFields() {
            return this.webviewerWidgetAnnotations().filter(annotation => {
                return this.isSignatureAnnotation(annotation) && !annotation.isSignedByAppearance()
                    && (this.isSellerInitialsField(annotation) || this.isSellerSignatureField(annotation))
            });
        },
        completeAgreement() {
            this.file
                ? this.upload()
                : this.toggleConfirmationModal();
        },
        toggleSubmitting() {
            this.submitting = !this.submitting;
        },
        toggleConfirmationModal() {
            this.confirmationModal = !this.confirmationModal;

            if (this.confirmationModal) this.clearValidationErrors();
        },
        closeEvent() {
            this.$emit('close');
        },
        signEvent() {
            this.$emit('sign');
            this.closeEvent();
        },
        uploadEvent() {
            this.$emit('upload');
            this.closeEvent();
        },
        cancelUploadEvent() {
            this.$emit('cancelUpload');
            this.closeEvent();
        },
    }
};
</script>

<style lang="scss" scoped>
.content-body {
    height: 100vh;

    .webviewer-container {
        display: flex;
        height: 100%;
        border: 1px solid rgba(0, 0, 0, 0.05);

        .webviewer {
            flex: 1;
        }
    }
}
</style>
