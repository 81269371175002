<template>
    <LoadingButton v-if="canSend" :disabled="loading || disable" :loading="loading" color="primary" variant="outline"
                   @click="remind">
        {{ buttonText }}
    </LoadingButton>
</template>

<script>
import taskHelper from "@/mixin/taskHelper";
import LoadingButton from "@/components/LoadingButton.vue";
import Tasks from "@/api/v2/endpoints/Tasks";

export default {
    name: 'TaskReminderButton',
    components: {LoadingButton},
    mixins: [taskHelper],
    inject: ['toast'],
    props: {
        task: Object,
        buttonText: {
            type: String,
            default: 'Remind Signer'
        }
    },
    data() {
        return {
            loading: false,
            disable: false,
        }
    },
    computed: {
        canSend() {
            return this.isContract && (
                (this.isPendingInternalSignature && this.isTaskInternalAssignee && !this.isTaskInternalSigner)
                || (this.isPendingExternalSignature && this.isTaskExternalAssignee && !this.isTaskExternalSigner)
            )
        }
    },
    methods: {
        remind() {
            this.toggleLoading();

            Tasks
                .reminder(this.task.id)
                .then(() => this.toast('info', 'Reminder sent successfully!'))
                .finally(() => this.toggleLoading());
        },
        toggleLoading() {
            this.loading = !this.loading;
            if (this.loading) this.disable = true;
        },
    }
}
</script>
